<template>
  <v-app class>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
      >
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Requisition Pending For Approval</h1>
          </div>
          <v-row no-gutters>
            <v-col cols="12" class="">
              <v-row no-gutters>
                <v-col cols="6">
                <v-select v-model="action" class="pt-5" :items="actions" item-value="id" item-text="name" :disabled="selected.length === 0"   label="Actions" return-object outlined dense/>
              </v-col>
               <v-col cols="6 d-flex">
                 <div class="submit-button-container">
                   <v-btn
                    small
                    class="btn btnSubmit"
                    :disabled="action === ''"
                    @click.prevent="dialog = true"
                    :loading ="loading"
                  >Submit</v-btn>
                 </div>
               </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="col-md-12 ml-auto mr-auto">
                <v-text-field v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details></v-text-field>
              </div>
           <div v-if="isLoading" class="text-center">
            <!-- <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular> -->
          </div>
           <v-data-table
                    :search="search"
                    show-select
                    :single-select="false"
                    v-model="selected"
                    :loading="isLoading"
                    loading-text="Loading Data. Please wait...."
                    :headers="headers"
                    :items="pendingReguisition"
                >
                <template v-slot:[`item.dateNeeded`]="{ item }">
                  {{item.dateNeeded | formatDate}}
                </template>
        <template v-slot:[`item.requisitionProcess`]="{ item }">
                     <span v-if="item.requisitionProcess === 0">Internal</span>
                    <span v-if ="item.requisitionProcess === 1">External</span>
                    <span v-if ="item.requisitionProcess === 2">Renewal</span>
                </template>
                 <template v-slot:[`item.requisitionType`]="{ item }">
                     <span v-if="item.requisitionType === 0">Addition</span>
                    <span v-if ="item.requisitionType === 1">Replacement</span>
                    <span v-if ="item.requisitionType === 2">Renewal</span>
                </template>
        <template v-slot:[`item.comment`]="{ item }">
          <input
            placeholder="Comment"
            v-model="item.comment"
            class="comment-box"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }" >
                    <div class="d-flex">
                    <Button :btnType="'Submit'" :color="'info'" :label="'View'" @onClick="view(item)" class="mr-2" />
                      <Button :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="openConfiModal(item)" class="mr-2"/>
                      <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openCancelModal(item)" class="mr-2" />
                    </div>
              </template>
      </v-data-table>
      <!-- View modal  -->
              <Dialog ref="requisitionViewModal" :title="'Requisition View Mode'" :width="700">
                <v-row v-if="requisit.length !==0">
                    <div class="row text-left">
                        <div class="text-caption col-md-6">Job Title: </div>
                            <div class="col-md-6"><b>{{requisit.jobTitle.name}}</b></div>

                    <div class="text-caption col-md-6">Department: </div>
                        <div class="col-md-6"><b> {{requisit.department.name}}</b></div>
                    <div class="text-caption col-md-6">Brief Job Description: </div>
                        <div class="col-md-6"><b> {{requisit.briefJobDescription}}</b></div>
                    <div class="text-caption col-md-6">Business Justification: </div>
                        <div class="col-md-6"><b> {{requisit.businessJustification}}</b></div>
                    <div class="text-caption col-md-6">Cost Centre: </div>
                        <div class="col-md-6"><b> {{requisit.costCentre}}</b></div>

                    <div class="text-caption col-md-6">Requisition Process: </div>
                        <span class="col-md-6" v-if="requisit.requisitionProcess == 0"> <b>Internal</b></span>
                        <span class="col-md-6" v-if="requisit.requisitionProcess == 1"> <b>External</b> </span>
                        <span class="col-md-6" v-if="requisit.requisitionProcess == 2"> <b>Renewal</b></span>

                    <div class="text-caption col-md-6" >Requisition Type: </div>
                        <div class="col-md-6" v-if="requisit.requisitionType == 0"> <b>Addition</b></div>
                        <div class="col-md-6" v-if="requisit.requisitionType == 1"> <b>Replacement </b></div>
                        <div class="col-md-6" v-if="requisit.requisitionType == 2"> <b>Renewal</b></div>

                    <div class="text-caption col-md-6">Skill Level: </div>
                        <div class="col-md-6"  v-if="requisit.skillLevel == 0"> <b>High Level </b></div>
                        <div class="col-md-6" v-if="requisit.skillLevel == 1"> <b>Low Level </b></div>

                    <div class="text-caption col-md-6">Education: </div>
                        <div class="col-md-6"> <b>{{requisit.education}}</b></div>
                    <div class="text-caption col-md-6">Experience: </div>
                        <div class="col-md-6"> <b>{{requisit.experience}}</b></div>
                    <div class="text-caption col-md-6">Date Needed: </div>
                        <div class="col-md-6"><b> {{requisit.dateNeeded | formatDate}}</b></div>
                    <div class="text-caption col-md-6">Status: </div>
                        <div class="col-md-6"  v-if="requisit.status == 0"> Pending </div>
                        <div class="col-md-6"  v-if="requisit.status == 1"> Approved </div>
                    </div>
                </v-row>
                   <v-divider></v-divider>
                <template v-slot:footer>
                </template>
              </Dialog>

          <!-- Reject dialog  -->
          <v-dialog v-model="rejectDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField  v-model="formData.comments" :label="'Comment'"/>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button
                  :label="'Reject'"
                  :btnType="'Submit'"
                  @onClick="reject"
                  :isLoading="rejecting"
                  :disabled="!valid"
                  class="mr-4"
                />
                <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-container>
      </div>
    </div>

    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="loading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="changeAction"/>

    <ConfirmationDialog :title="'Are you sure you want to approve?'" :isLoading="loading" :dialog="confdialog" :btnTitle="'Yes'" @close="confdialog = false" @btnAction="approve"/>
  </v-app>
</template>
<script>
import Dialog from '@/components/ui/Dialog.vue'
import Button from '@/components/ui/form/Button.vue'
import TextField from '@/components/ui/form/TextField'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
// import moment from 'moment'
import { FETCH_REQUISITION, APPROVE_REQUISITION } from '@/store/action-type'
// import SelectField from '@/components/ui/form/SelectField.vue'
// import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import { employeeRequisition, requestService, employeeService, messagingService } from '@/services'

export default {
  components: {
    Button,
    Dialog,
    TextField,
    ConfirmationDialog
    // PersonnelSearch
    // SelectField
  },
  data () {
    return {
      isLoading: false,
      rejectDialog: false,
      alertMessage: '',
      forwarding: false,
      alert: false,
      search: '',
      dialog: false,
      confdialog: null,
      valid: false,
      loading: false,
      loadingLogs: false,
      personnelSearch: false,
      alertType: '',
      item: null,      
      DelegateName: null,
      DelegateEmail: null,
      //   selectAll: '',
      startDate: '2020-09-19',
      endDate: '2020-10-18',
      salaryPeriod: '',
      rejecting: false,
      action: '',
      comment: '',
      selected: [],
      salaryPeriods: [],
      requisit: [],
      approver: '',
      formData: {
        comments: '',
        id: ''
      },
      searchRequisit: '',
      headers: [
        {
          text: '',
          value: 'selected',
          sortable: false
        },
        {
          text: 'Job Title',
          value: 'jobTitle.name'
        },
        {
          text: 'Department',
          value: 'department.name'
        },
        {
          text: 'Requisition Type',
          value: 'requisitionType'
        },
        {
          text: 'Requisition Process',
          value: 'requisitionProcess'
        },
        {
          text: 'Date Needed',
          value: 'dateNeeded',
          align: 'center'
        },
        {
          text: 'Comment',
          value: 'comment',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      pendingReguisition: [],
      searchRequisition: [],
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }]
    }
  },
  computed: {
    requisitions () {
      return this.$store.getters.requisitions
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    view (item) {
      this.loadingLogs = true
      employeeRequisition.getRequisitionById(item.id).then(({ data }) => {
        console.log(data)
        this.requisit = data
      }).catch(() => {
      }).finally(() => {
        this.loadingLogs = false
      })

      this.$refs.requisitionViewModal.openModal()
    },
    // Handle single approval endpoint
    approve () {
      this.loading = true
      const data = []
      data.push({
        Id: this.item.id,
        Status: 1,
        Comments: this.item.comment
      })

      // console.log('Single Data:', data)
      this.$store
        .dispatch(APPROVE_REQUISITION, data).then(() => {
          this.showAlertMessage(`Requisition Action successful`, 'success')
          const newArray = this.pendingReguisition.filter(i => i.id !== this.item.id)
          this.pendingReguisition = newArray
        })
        .catch((error) => {
          console.log('error:', error)
          this.showAlertMessage('Approve Rquisition failed', 'error')
        }).finally(() => {
          this.loading = false
          this.confdialog = false
        })
    },
    openRejectModal (item) {
      this.rejectDialog = true
      this.item = item
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    openConfiModal (item) {
      this.item = item
      this.confdialog = true
    },
    reject () {
      this.rejecting = 0
      this.item.id = this.item.id
      this.item.comment = this.formData.comments
      this.$store
        .dispatch(APPROVE_REQUISITION, this.item)
        .then((result) => {
          this.showAlertMessage('Reject successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.showAlertMessage('Reject failed', 'error')
        }).finally(() => {
          this.rejecting = false
          this.formData.comments = ''
          this.rejectDialog = false
        })
    },
    loadPendRequisitions () {
      this.isLoading = true
      employeeService.getPendingRequisition().then(result => {
        this.pendingReguisition = result.data
        this.searchRequisition = result.data
      }).catch(() => {
        this.showAlertMessage('There are no items pending your approval', 'error')
      }).finally(() => {
        this.isLoading = false
      })
    },
    sendMail () {
      messagingService.sendMessage().then((result) => {
        console.log(result)
      })
    },

    changeAction () {
      let status = this.action.id
      this.loading = true
      const data = []
      console.log('selected: ', this.selected)

      this.selected.forEach(select => {
        data.push({
          Status: status,
          Comments: select.comment,
          Id: select.id
        })
      })

      console.log(data)
      requestService.approveBulkRequisition(data).then(result => {
        this.showAlertMessage(`Requisition Action successful`, 'success')
        const newArray = this.pendingReguisition.filter(i => !this.selected.some(j => j.id === i.id))
        console.log('New Array', newArray)
        this.pendingReguisition = newArray
      }).catch(error => {
        console.log(error)
        this.showAlertMessage('Requisition Action Failed', 'error')
      }).finally(() => {
        this.action = []
        this.comment = ''
        this.selected = [ ]
        this.loading = false
        this.dialog = false
      })
    },
    fetchRequisition () {
      this.isLoading = true
      this.$store
        .dispatch(FETCH_REQUISITION)
        .then(() => {
          this.isLoading = false
        })
        .catch((error) => {
          if (error) {
            console.log('Error', error)
            this.isLoading = false
            this.showAlertMessage('Unable to display Requisition', 'error')
          }
        })
    }
  },
  mounted () {
    this.fetchRequisition()
    this.loadPendRequisitions()
  }
}
</script>
<style scoped>
  .row b {
    word-wrap: break-word;
    word-break: break-all;
  }
.view-button {
  text-align: left;
  border: none;
  color: #0066b2;
}
.view-button:focus {
  border: none;
}
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.actionBar {
  display: flex;
    align-items: center;
    height: 100%;
}
.actionBarContainer{
  width: 100%;
}
.submit-button-container {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.btn{
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: "GothamBold",Arial,sans-serif;
    font-size: 14px;
    padding: 18.5px 20px  !important;
    text-decoration: none;
    border-bottom-style: none;
    border-radius: 3;
    margin-left: 4px;
}
.btnSubmit{
  background: #0066b2 !important;
}

</style>
